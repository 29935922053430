import { ContactRow } from "@shared/models/Contact";
import Avatar from "components/core/Avatar";
import { TimesCircleSolidIcon } from "components/core/Icon";
import { getFullName } from "helpers/contact";
import { FC } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

type MultiSelectContactCardProps = {
  contact: ContactRow;
  onClickRemove: (contact: ContactRow) => void;
};

const MultiSelectContactCard: FC<MultiSelectContactCardProps> = ({ contact, onClickRemove }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip();

  return (
    <div className="px-4 py-3.5 my-2 rounded-md bg-zinc-50 dark:bg-zinc-900 sm:flex sm:justify-between sm:items-center">
      <Avatar
        firstName={contact.givenName}
        lastName={contact.surname}
        imageUrl={contact.pictureUrl}
        className="w-10 h-10 text-md"
      />

      <div className="sm:ml-4 sm:flex-1">
        <div className="-mt-px font-medium text-zinc-900 dark:text-zinc-100 text-md">
          {getFullName(contact)}
        </div>
        <p className="-mt-px text-xs text-zinc-600 dark:text-zinc-400 sm:flex sm:items-center">
          {contact.jobTitle}{" "}
          {contact.departmentName && contact.jobTitle && `- ` && contact.departmentName}
        </p>
        <p className="-mt-px text-xs text-zinc-600 dark:text-zinc-400 sm:flex sm:items-center">
          {contact.companyName}
        </p>
      </div>

      <div className="sm:ml-6 sm:flex-shrink-0">
        <button
          ref={setTriggerRef}
          tabIndex={-1}
          onClick={() => onClickRemove(contact)}
          className="items-center justify-center w-8 h-9"
        >
          <TimesCircleSolidIcon className="cursor-pointer text-zinc-400 hover:text-zinc-500" />
        </button>
        {visible && (
          <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
            <div {...getArrowProps({ className: "tooltip-arrow" })} />
            Remove from selection
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectContactCard;
