import { FC, PropsWithChildren } from "react";

const PageHeader: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="flex-row items-center hidden pt-3 pb-2 mx-4 lg:flex lg:pt-6 lg:mx-8">
      <h1 className="self-center text-lg font-bold leading-7 align-baseline text-zinc-900 dark:text-zinc-100 lg:text-2xl">
        {children}
      </h1>
    </div>
  );
};

export default PageHeader;
