import uuid from "@packages/common/uuid";
import { sanitizeContactData } from "@shared/helpers/contact";
import { ContactRow } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { getEntityId } from "@shared/models/helpers";
import {
  useCreateContactGroupMutation,
  useCreateContactMutation,
  useUpdateContactGroupMutation,
} from "@web/integrations/contact/api";
import classNames from "clsx";
import { useRouter } from "next/router";
import { FC, useEffect, useReducer, useState } from "react";
import { useKey } from "rooks";

import Button, { ButtonVariant } from "../../core/Button";
import { showToast, ToastLevel } from "../../core/Toast";
import ArbitraryDatesField from "./fields/ArbitraryDatesField";
import BirthdayField from "./fields/BirthdayField";
import EmailsField from "./fields/EmailsField";
import GroupsField from "./fields/GroupsField";
import ImHandlesField from "./fields/ImHandlesField";
import JobsField from "./fields/JobsField";
import NamesField from "./fields/NamesField";
import NotesField from "./fields/NotesField";
import PhoneNumbersField from "./fields/PhoneNumbersField";
import PhysicalAddressesField from "./fields/PhysicalAddressesField";
import RelativesField from "./fields/RelativesField";
import SyncToggleField from "./fields/SyncToggleField";
import WebPagesField from "./fields/WebPagesField";
import { UpdateContactDataAction } from "./types";

type CreateNewContactProps = {
  isDoNotSync: ContactRow["isDoNotSync"];
  contacts?: ContactRow[];
  groups?: ContactGroupRowForDisplay[];
  onCancel?: () => void;
  onCreateNewContact: (contact: ContactRow) => void;
};

const CreateNewContact: FC<CreateNewContactProps> = ({
  isDoNotSync,
  contacts,
  groups,
  onCreateNewContact,
  onCancel,
}) => {
  const router = useRouter();

  const [createContact] = useCreateContactMutation();
  const [createContactGroup] = useCreateContactGroupMutation();
  const [updateContactGroup] = useUpdateContactGroupMutation();

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [groupsToAdd, setGroupsToAdd] = useState<ContactGroupRowForDisplay[]>([]);

  // Use reducer to hold newly created contact data
  const contactDataReducer = (
    state: Partial<ContactRow>,
    action: UpdateContactDataAction
  ): Partial<ContactRow> => {
    return { ...state, [action.type]: action.payload };
  };

  const [contactData, dispatch] = useReducer(contactDataReducer, { isDoNotSync });

  useEffect(() => {
    dispatch({ type: "isDoNotSync", payload: isDoNotSync });
  }, [isDoNotSync]);

  const onClickCreate = async () => {
    setIsCreating(true);

    const { validContact: sanitizedContact } = sanitizeContactData(contactData);
    const newContact = {
      ...sanitizedContact,
      id: getEntityId({ id: uuid(), isContactGroup: false, remoteApiId: "" }),
    } as ContactRow;
    try {
      createContact(newContact);
      onCreateNewContact(newContact);

      // Add the newly created contact to groups
      if (groupsToAdd.length > 0) {
        groupsToAdd.forEach((group) => {
          updateContactGroup({
            ...group,
            contactIds: [...(group.contactIds || []), newContact.id],
          });
        });
      }

      router
        .push("/contacts/[[...slug]]", `/contacts/${newContact.id}`, { shallow: true })
        .then(() => {
          setIsCreating(false);
          showToast({
            title: `Added ${sanitizedContact.givenName} ${sanitizedContact.surname} to your contacts!`,
            level: ToastLevel.Success,
          });
        });
    } catch (error) {
      console.error("Encountered error creating data. Error: ", error);
      showToast({
        title: `Failed to create ${sanitizedContact.givenName} ${sanitizedContact.surname}`,
        level: ToastLevel.Warning,
      });
      setIsCreating(false);
    }
  };

  const onClickCancel = () => {
    if (onCancel) onCancel();
    router.push("/contacts");
  };

  const createGroup = async (group: Partial<ContactGroupRowForDisplay>) => {
    await createContactGroup(group);
  };

  const addContactToGroup = async (_contactId: string, group: ContactGroupRowForDisplay) => {
    setGroupsToAdd([...groupsToAdd, group]);
  };

  const removeContactFromGroup = async (_contactId: string, group: ContactGroupRowForDisplay) => {
    setGroupsToAdd(groupsToAdd.filter((groupToAdd) => groupToAdd.id !== group.id));
  };

  useKey("Escape", onClickCancel);

  return (
    <div>
      <div className="sticky top-0 bg-primary z-10 px-4 pt-6 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex flex-row items-center justify-between space-x-2 sm:space-x-4">
          <h1 className="text-lg font-semibold truncate text-primary sm:font-bold sm:text-3xl">
            Add New Contact
          </h1>
          <div className="flex flex-row items-center space-x-2">
            <Button
              variant={ButtonVariant.Success}
              hotkey={["Alt", "KeyS"]}
              disabled={isCreating}
              onClick={onClickCreate}
            >
              <span className="px-4">{isCreating ? "Creating…" : "Create"}</span>
            </Button>
            <Button
              variant={ButtonVariant.Secondary}
              hotkey={["Escape"]}
              hotkeyMatchAll={false}
              disabled={isCreating}
              onClick={onClickCancel}
            >
              <span className="px-2">Cancel</span>
            </Button>
          </div>
        </div>
        <div className="mt-4 sm:mt-6 divider" />
      </div>

      {/* Main fields */}
      <dl
        className={classNames(
          "flex flex-col w-full px-4 pb-8 space-y-6 divide-primary-y sm:px-6 lg:px-8",
          isCreating ? "opacity-60" : "opacity-100"
        )}
      >
        <NamesField key="names" contactData={contactData} dispatch={dispatch} isEditing />
        <JobsField key="jobs" contactData={contactData} dispatch={dispatch} isEditing />
        <EmailsField key="emails" contactData={contactData} dispatch={dispatch} isEditing />
        <PhoneNumbersField
          key="phoneNumbers"
          contactData={contactData}
          dispatch={dispatch}
          isEditing
        />
        <PhysicalAddressesField
          key="physicalAddresses"
          contactData={contactData}
          dispatch={dispatch}
          isEditing
        />
        <WebPagesField key="webPages" contactData={contactData} dispatch={dispatch} isEditing />
        <ImHandlesField key="imHandles" contactData={contactData} dispatch={dispatch} isEditing />
        <NotesField key="notes" contactData={contactData} dispatch={dispatch} isEditing />
        <BirthdayField key="birthday" contactData={contactData} dispatch={dispatch} isEditing />
        <ArbitraryDatesField key="dates" contactData={contactData} dispatch={dispatch} isEditing />
        <RelativesField
          key="relatives"
          contactData={contactData}
          dispatch={dispatch}
          contacts={contacts}
          isEditing
        />
        <GroupsField
          key="groups"
          contactData={contactData}
          dispatch={dispatch}
          isEditing
          groups={groups || []}
          createGroup={createGroup}
          addContactToGroup={addContactToGroup}
          removeContactFromGroup={removeContactFromGroup}
        />
        <SyncToggleField contactData={contactData} dispatch={dispatch} isEditing />
      </dl>
    </div>
  );
};

export default CreateNewContact;
