import { IconType } from "components/core/Icon";
import { FC } from "react";

type MultiSelectionActionButtonProps = {
  Icon: IconType;
  text: string;
  onClickAction: () => void;
};

const MultiSelectionActionButton: FC<MultiSelectionActionButtonProps> = ({
  Icon,
  text,
  onClickAction,
}) => {
  return (
    <button
      type="button"
      className="flex flex-col items-center px-5 py-4 rounded-md hover:shadow-sm hover:bg-purple-100 dark:hover:bg-opacity-20 dark:hover:bg-purple-400"
      onClick={onClickAction}
    >
      <Icon size="lg" className="text-purple-700 dark:text-purple-500" />
      <p className="mt-1 font-medium text-zinc-700 dark:text-white text-md">{text}</p>
    </button>
  );
};

export default MultiSelectionActionButton;
