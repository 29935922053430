import { objKeys } from "@packages/common/object";
import { EventType } from "@shared/models/types";
import { useAddUserEventsMutation } from "@web/integrations/user/api";
import { useCallback, useRef } from "react";

export function useContactOpenedActivity() {
  const contactIds = useRef<{ [id: string]: true }>({});
  const timer = useRef<NodeJS.Timeout>();

  const [addUserEvents] = useAddUserEventsMutation();

  const flushViewedContactIds = useCallback(async () => {
    if (timer && timer.current) clearTimeout(timer.current);
    const ids = objKeys(contactIds.current);
    if (ids.length > 0) {
      const createdAt = new Date().setHours(0, 0, 0, 0);
      await addUserEvents(
        ids.map((entityId) => {
          return {
            entityId: entityId as string,
            createdAt,
            type: EventType.OpenContact,
          };
        })
      );
      contactIds.current = {};
    }
  }, []);

  const addViewedContact = useCallback(
    (id: string) => {
      contactIds.current = { ...contactIds.current, [id]: true };
      if (timer && timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(flushViewedContactIds, 1000 * 15);
    },
    [flushViewedContactIds]
  );

  return { addViewedContact };
}
