import { Dialog } from "@headlessui/react";
import { ContactRow } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { getEntityId } from "@shared/models/helpers";
import Button, { ButtonVariant } from "components/core/Button";
import { TagIcon } from "components/core/Icon";
import Modal from "components/core/Modal";
import { TagItem } from "components/core/Tag";
import TagsInput from "components/core/TagsInput";
import isEmpty from "lodash/isEmpty";
import { FC, useCallback, useMemo, useRef, useState } from "react";

import uuid from "../../../../common/uuid";

type MultiSelectGroupDialogProps = {
  contacts: ContactRow[];
  groups?: ContactGroupRowForDisplay[];
  isMultiSelectGroupDialogOpen: boolean;
  setIsMultiSelectGroupDialogOpen: (open: boolean) => void;
  onUpdateOrCreateGroups: (
    groupsToUpdate: ContactGroupRowForDisplay[],
    groupsToCreate: Partial<ContactGroupRowForDisplay>[]
  ) => void;
  onClickCancel: () => void;
};

const MultiSelectGroupDialog: FC<MultiSelectGroupDialogProps> = ({
  contacts,
  groups,
  isMultiSelectGroupDialogOpen,
  setIsMultiSelectGroupDialogOpen,
  onUpdateOrCreateGroups,
  onClickCancel,
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const tagsInputRef = useRef<HTMLInputElement | null>(null);

  const [updatedGroups, setUpdatedGroups] = useState<ContactGroupRowForDisplay[]>([]);
  const [createdGroups, setCreatedGroups] = useState<Partial<ContactGroupRowForDisplay>[]>([]);
  const [currentTagInputString, setCurrentTagInputString] = useState<string>("");

  const [tagItems, suggestions] = useMemo<[TagItem[], TagItem[]]>(() => {
    if (isEmpty(contacts) || isEmpty(groups)) {
      return [[], []];
    }

    // Create tags list from groups that contain this contact ID
    const tagItems: TagItem[] = [
      ...updatedGroups,
      ...(createdGroups as ContactGroupRowForDisplay[]),
    ].map((group) => ({ id: group.id, name: group.name }));

    // Create suggestions list from groups that don't contain this contact ID
    const suggestions: TagItem[] = (groups || [])
      .filter((group) => !tagItems.map((o) => o.id).includes(group.id))
      .map((group) => ({
        id: group.id,
        name: group.name,
      }));

    return [tagItems, suggestions];
  }, [contacts, groups, updatedGroups, createdGroups]);

  const onCreateTag = useCallback(
    (name: string) => {
      const existingGroup = groups?.find((o) => o.name.toLowerCase() === name.toLowerCase());
      if (existingGroup) {
        // This group already exists, add contactIds to it instead of creating a new group
        onAddToTag(existingGroup.id);
        return;
      }

      const contactIds = contacts.map((o) => o.id);
      setCreatedGroups([
        ...createdGroups,
        {
          id: getEntityId({ id: uuid(), isContactGroup: true, remoteApiId: "" }),
          name,
          contactIds,
        },
      ]);
    },
    [contacts, groups, createdGroups, updatedGroups]
  );

  const onAddToTag = useCallback(
    (tagId: string) => {
      const matchedSuggestion = (groups || []).find((group) => group.id === tagId);
      if (matchedSuggestion) {
        const updatedContactIdsSet = new Set(matchedSuggestion.contactIds);
        contacts.forEach((contact) => updatedContactIdsSet.add(contact.id));
        const updatedGroup: ContactGroupRowForDisplay = {
          ...matchedSuggestion,
          contactIds: Array.from(updatedContactIdsSet),
        };
        setUpdatedGroups([...updatedGroups, updatedGroup]);
      }
    },
    [contacts, groups, createdGroups, updatedGroups]
  );

  const onRemoveTag = useCallback(
    (tagId: string) => {
      setCreatedGroups(createdGroups.filter((o) => o.id !== tagId));
      setUpdatedGroups(updatedGroups.filter((o) => o.id !== tagId));
    },
    [contacts, createdGroups, updatedGroups]
  );

  const onClickConfirm = useCallback(() => {
    const newCreatedGroups = [...createdGroups];
    if (!isEmpty(currentTagInputString)) {
      newCreatedGroups.push({
        id: getEntityId({ id: uuid(), isContactGroup: true, remoteApiId: "" }),
        name: currentTagInputString,
        contactIds: contacts.map((o) => o.id),
      });
    }
    onUpdateOrCreateGroups(updatedGroups, newCreatedGroups);
  }, [contacts, updatedGroups, createdGroups, currentTagInputString]);

  return (
    <Modal
      initialFocusRef={tagsInputRef}
      isModalOpen={isMultiSelectGroupDialogOpen}
      setIsClosed={(open) => {
        setIsMultiSelectGroupDialogOpen(open);
      }}
      className="p-4 text-left align-middle sm:max-w-xl sm:w-full sm:p-6"
    >
      <div className="sm:flex sm:items-start">
        {/* Icon */}
        <div className="mt-2 icon-container" aria-hidden="true">
          <TagIcon size="lg" className="icon-color-purple" />
        </div>

        <div className="mt-4 text-center sm:mt-2 sm:ml-4 sm:text-left">
          {/* Title */}
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100"
          >
            Add / Create Groups
          </Dialog.Title>

          {/* Description */}
          <div className="mt-1.5">
            <p className="text-sm text-zinc-500">
              Add {contacts.length >= 1 ? `${contacts.length} contacts` : "this contact"} to
              existing groups, or create new groups
            </p>
          </div>

          {/* Content */}
          <div className="flex my-4">
            <TagsInput
              tagItems={tagItems}
              suggestions={suggestions}
              onCreateTag={onCreateTag}
              onAddTag={onAddToTag}
              onRemoveTag={onRemoveTag}
              onInputStringChange={setCurrentTagInputString}
              forwardedRef={tagsInputRef}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row-reverse mt-8">
        <Button className="w-full mx-1 sm:mx-0 sm:w-auto sm:text-sm" onClick={onClickConfirm}>
          Confirm
        </Button>

        {/* Cancel button */}
        <Button
          className="w-full mx-1 sm:mx-3 sm:w-auto sm:text-sm"
          variant={ButtonVariant.Secondary}
          onClick={() => {
            onClickCancel();
            setUpdatedGroups([]);
            setCreatedGroups([]);
          }}
          innerRef={cancelButtonRef}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default MultiSelectGroupDialog;
