import { ContactRow } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import {
  exportContactsToCsvDownload,
  exportContactsToVCardDownload,
} from "@web/integrations/exportContacts";
import Alert from "components/core/Alert";
import Button, { ButtonVariant } from "components/core/Button";
import { CloudDownloadIcon, TagIcon, TrashIcon } from "components/core/Icon";
import { showToast, ToastLevel } from "components/core/Toast";
import {
  useCreateContactGroupsMutation,
  useDeleteContactsMutation,
  useUpdateContactGroupMutation,
} from "integrations/contact/api";
import { selectMultiSelectedContacts } from "integrations/contact/selectors";
import contactSlice from "integrations/contact/slice";
import { useAppDispatch } from "integrations/redux/store";
import { useRouter } from "next/router";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useKey } from "rooks";

import MultiSelectionActionButton from "./MultiSelectActionButton";
import MultiSelectContactCard from "./MultiSelectContactCard";
import MultiSelectGroupDialog from "./MultiSelectGroupDialog";

type MultiSelectContactsProps = {
  groups?: ContactGroupRowForDisplay[];
};

const MultiSelectContacts: FC<MultiSelectContactsProps> = ({ groups }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const selectedContacts = useSelector(selectMultiSelectedContacts);

  const [createGroups] = useCreateContactGroupsMutation();
  const [updateGroup] = useUpdateContactGroupMutation();
  const [deleteContacts] = useDeleteContactsMutation();

  const [isMultiSelectGroupDialogOpen, setIsMultiSelectGroupDialogOpen] = useState(false);
  const [isDeleteContactsAlertOpen, setIsDeleteContactsAlertOpen] = useState(false);

  const onUpdateOrCreateGroups = (
    groupsToUpdate: ContactGroupRowForDisplay[],
    groupsToCreate: Partial<ContactGroupRowForDisplay>[]
  ) => {
    // Sanitize groupsToUpdate to remove duplicate contact ids
    const updateGroupPromises = groupsToUpdate.map((group) => {
      return updateGroup({
        ...group,
        contactIds: [...Array.from(new Set(group.contactIds))],
      });
    });

    const createGroupsPromise = createGroups(groupsToCreate);

    // TODO: Use batch update / create group endpoints
    Promise.allSettled([...updateGroupPromises, createGroupsPromise])
      .then(() => {
        showToast({
          title: `Successfully updated groups for ${selectedContacts.length} contacts.`,
          level: ToastLevel.Success,
        });
      })
      .catch((error) => {
        console.error("Failed to add / create groups for contacts. Error: ", error);
        showToast({
          title: `Failed to update contacts. Please contact support@titledock.com.`,
          level: ToastLevel.Warning,
        });
      });

    // Clear multi selection
    dispatch(contactSlice.actions.clearMultiSelectedContacts());
  };

  const onDeleteContacts = (contacts: ContactRow[]) => {
    deleteContacts({ contactIds: contacts.map((o) => o.id) })
      .then(() => {
        showToast({
          title: `Successfully deleted ${contacts.length} contacts.`,
          level: ToastLevel.Success,
        });
      })
      .catch((error) => {
        console.error("Failed to delete contacts. Error: ", error);
        showToast({
          title: `Failed to delete contacts. Please contact support@titledock.com.`,
          level: ToastLevel.Warning,
        });
      });

    // Optimistically clear multiselections and push to /contacts
    dispatch(contactSlice.actions.clearMultiSelectedContacts());
    router.push("/contacts");
  };

  const onRemoveContactFromMultiSelection = useCallback(
    (contact: ContactRow) => {
      dispatch(
        contactSlice.actions.setMultiSelectedContacts({
          contacts: selectedContacts.filter((o) => o.id !== contact.id),
        })
      );
    },
    [selectedContacts]
  );

  const onCancelMultiSelection = () => {
    dispatch(contactSlice.actions.clearMultiSelectedContacts());
  };

  useKey("Escape", onCancelMultiSelection);
  useKey(["Backspace", "Delete"], () => setIsDeleteContactsAlertOpen(true));

  const actionButtons = useMemo(() => {
    return (
      <>
        <MultiSelectionActionButton
          Icon={TagIcon}
          text="Group"
          onClickAction={() => setIsMultiSelectGroupDialogOpen(true)}
        />
        <MultiSelectionActionButton
          Icon={CloudDownloadIcon}
          text="Export CSV"
          // todo - make all contact types into actual ContactRow
          onClickAction={() => exportContactsToCsvDownload(selectedContacts as ContactRow[])}
        />{" "}
        <MultiSelectionActionButton
          Icon={CloudDownloadIcon}
          text="Export vCard"
          onClickAction={() => exportContactsToVCardDownload(selectedContacts as ContactRow[])}
        />
        <MultiSelectionActionButton
          Icon={TrashIcon}
          text="Delete"
          onClickAction={() => setIsDeleteContactsAlertOpen(true)}
        />
      </>
    );
  }, [selectedContacts]);

  return (
    <>
      <div>
        {/* Header */}
        <div className="px-4 pt-6 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center justify-between space-x-2 sm:space-x-4">
            <h1 className="text-lg font-semibold truncate text-primary sm:font-bold sm:text-3xl">
              {selectedContacts.length} Contacts
            </h1>
            <div className="flex flex-row items-center space-x-2">
              <Button variant={ButtonVariant.Secondary} onClick={onCancelMultiSelection}>
                <span className="px-2">Cancel</span>
              </Button>
            </div>
          </div>
          <div className="mt-4 sm:mt-6 divider" />
        </div>

        {/* Content */}
        <div className="w-full px-4 mt-8 sm:mt-12 sm:mx-auto sm:max-w-3xl lg:px-8">
          {/* Action buttons */}
          <div className="flex flex-row justify-between max-w-xs mx-auto">{actionButtons}</div>

          {/* List of selected contacts */}
          <div className="px-3 py-2 mt-4 overflow-auto border shadow bg-primary sm:rounded-lg border-zinc-100 dark:border-zinc-600">
            {selectedContacts?.map((contact, index) => (
              <MultiSelectContactCard
                key={index}
                contact={contact}
                onClickRemove={() => onRemoveContactFromMultiSelection(contact)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Groups dialog */}
      <MultiSelectGroupDialog
        contacts={selectedContacts}
        groups={groups}
        isMultiSelectGroupDialogOpen={isMultiSelectGroupDialogOpen}
        setIsMultiSelectGroupDialogOpen={setIsMultiSelectGroupDialogOpen}
        onUpdateOrCreateGroups={onUpdateOrCreateGroups}
        onClickCancel={() => setIsMultiSelectGroupDialogOpen(false)}
      />

      {/* Delete confirmation alert */}
      <Alert
        title={`Delete ${selectedContacts.length} Contacts?`}
        variant={ButtonVariant.Danger}
        isAlertOpen={isDeleteContactsAlertOpen}
        onClose={setIsDeleteContactsAlertOpen}
        actionButtonTitle="Delete Contacts"
        actionHandler={() => onDeleteContacts(selectedContacts)}
        cancelHandler={() => setIsDeleteContactsAlertOpen(false)}
      />
    </>
  );
};

export default MultiSelectContacts;
