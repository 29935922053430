import { ContactRow } from "@shared/models/Contact";
import { IsDoNotSync } from "@shared/models/types";
import { flattenArrayOrDictionary, isEmpty } from "@web/helpers/array";
import { isValidUuid } from "@web/helpers/string";
import { SortableKeys, useContacts } from "@web/hooks/useContacts";
import { ContactSearchQueryContext, useContactList } from "@web/hooks/useSearch";
import { useContactOpenedActivity } from "@web/hooks/useUserActivity";
import { useGetContactGroupsQuery } from "@web/integrations/contact/api";
import { getIdIndex } from "@web/integrations/contact/helpers";
import classNames from "clsx";
import Tag from "components/core/Tag";
import { generateGroupTagColors } from "helpers/group";
import useDarkMode from "hooks/useDarkMode";
import { selectMultiSelectedContacts, selectSelectedGroups } from "integrations/contact/selectors";
import contactSlice from "integrations/contact/slice";
import { useAppDispatch } from "integrations/redux/store";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useKey, useKeys } from "rooks";

import BreadcrumbNavigation from "../core/BreadcrumbNavigation";
import Button, { ButtonVariant } from "../core/Button";
import { SearchIcon, TimesCircleSolidIcon } from "../core/Icon";
import PageHeader from "../core/PageHeader";
import TextInput from "../core/TextInput";
import LoadingSpinner from "../loading/LoadingSpinner";
import ContactDetails from "./details";
import CreateNewContact from "./details/new";
import MultiSelectContacts from "./multiselect";

const ContactsComponent: FC = () => {
  const [isDarkMode] = useDarkMode();
  const router = useRouter();
  const { slug } = router.query; // "new" or contact id

  const [uriRoot, uriSub] = slug || [];

  const dispatch = useAppDispatch();

  const { contacts, timestamp, contactIndex } = useContacts();
  const { data: groups } = useGetContactGroupsQuery();

  const selectedGroups = useSelector(selectSelectedGroups);
  const multiSelectedContacts = useSelector(selectMultiSelectedContacts);

  const [selectedContactId, setSelectedContactId] = useState<ContactRow["id"] | null>(
    isValidUuid(uriRoot) ? uriRoot : null
  );
  const [showAddNewContact, setShowAddNewContact] = useState<boolean>(uriRoot === "new");
  const [contactSortKey] = useState<SortableKeys>("surname");

  const { addViewedContact } = useContactOpenedActivity();

  useEffect(() => {
    if (selectedContactId) addViewedContact(selectedContactId);
  }, [addViewedContact, selectedContactId]);

  useEffect(() => {
    if (isValidUuid(uriRoot)) {
      setShowAddNewContact(false);
      setSelectedContactId(uriRoot);
    }
  }, [uriRoot]);

  useKeys(["Alt", "KeyN"], () => {
    router.push("/contacts/new").then(() => setShowAddNewContact(true));
  });

  useKeys(["Alt", "KeyP"], () => {
    router.replace("/contacts/new/pocket").then(() => setShowAddNewContact(true));
  });

  const onSetSelectedContact = (contact: ContactRow) => {
    setShowAddNewContact(false);
    setSelectedContactId(contact.id);
  };

  const onClickAddNewContact = () => {
    setShowAddNewContact(true);
    setSelectedContactId(null);
  };

  const onClickClearQuery = () => {
    setQuery("");
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Escape") {
      setQuery("");
    }
  };

  const onRemoveSelectedGroupTag = (groupId: string) => {
    dispatch(contactSlice.actions.removeSelectedGroup({ groupId }));
  };

  const selectedContact = useMemo(() => {
    if (contacts) {
      return selectedContactId ? contacts[contactIndex[selectedContactId]] : contacts[0];
    }
    return null;
  }, [contactIndex, contacts, selectedContactId]);

  const { ContactList, allContactsByLetter, searchResult, query, setQuery, queryOnChange } =
    useContactList("", contactSortKey, selectedContact);

  const MainDetailsComponent = useMemo(() => {
    if (showAddNewContact) {
      return (
        <CreateNewContact
          contacts={contacts}
          isDoNotSync={uriRoot === "new" && uriSub === "pocket" ? IsDoNotSync.YES : IsDoNotSync.NO}
          groups={groups}
          onCreateNewContact={onSetSelectedContact}
          onCancel={() => setShowAddNewContact(false)}
        />
      );
    } else if (multiSelectedContacts.length > 0) {
      return <MultiSelectContacts groups={groups} />;
    } else if (selectedContact) {
      return (
        <ContactSearchQueryContext.Provider value={query}>
          <ContactDetails contact={selectedContact} contacts={contacts} groups={groups} />
        </ContactSearchQueryContext.Provider>
      );
    }
    return <LoadingSpinner />;
  }, [
    showAddNewContact,
    multiSelectedContacts.length,
    selectedContact,
    contacts,
    uriRoot,
    uriSub,
    groups,
    query,
    timestamp,
  ]);

  useEffect(() => {
    if (isEmpty(contacts) || uriRoot === "new") {
      return;
    }
    let initialContact: ContactRow | undefined;
    if (query && searchResult) {
      // If search is active, fetch matching contact from search results array.
      const flattenedSearchResults = flattenArrayOrDictionary<ContactRow>(searchResult);
      initialContact =
        flattenedSearchResults.find((contact) => contact.id === uriRoot) ||
        flattenedSearchResults[0];
    } else {
      if (uriRoot === undefined && !isEmpty(allContactsByLetter) && allContactsByLetter) {
        // If user lands on /contacts without a slug, focus on first contact from allContactsByLetter
        const flattenedContacts = flattenArrayOrDictionary<ContactRow>(allContactsByLetter);
        initialContact = flattenedContacts[0];
      } else if (uriRoot && isValidUuid(uriRoot)) {
        // If user lands on /contacts with a valid contact id, focus on that contact if not already
        // selected.
        initialContact = contacts?.find((contact) => contact.id === uriRoot);
      }
    }

    if (initialContact && initialContact.id !== selectedContactId) {
      setSelectedContactId(initialContact.id);
    }
  }, [contacts, allContactsByLetter, uriRoot, query, searchResult]);

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchInputHotkeyHandler = useCallback((e: KeyboardEvent) => {
    if (searchInputRef.current) {
      e.preventDefault();
      searchInputRef.current.focus();
      if (searchInputRef.current.value) {
        searchInputRef.current?.select();
      }
    }
  }, []);

  useKey(
    ["ArrowUp", "ArrowDown"],
    () => {
      searchInputRef.current?.blur();
    },
    { when: document.activeElement === searchInputRef.current }
  );

  return (
    <div className="flex flex-col flex-1 h-full min-w-0 overflow-hidden bg-primary">
      <div className="relative flex flex-1 overflow-hidden">
        {/* Contact search + filtering */}
        <aside
          className={classNames(
            "relative flex flex-col flex-shrink-0 border-primary-r",
            isValidUuid(uriRoot) ? "hidden xl:flex xl:w-96" : "flex w-full xl:w-96"
          )}
        >
          {/* Contacts header */}
          <PageHeader>
            Contacts
            {selectedGroups?.length > 0 && (
              <span className="text-sm text-zinc-500"> ({selectedGroups.length} groups)</span>
            )}
          </PageHeader>

          {/* Search section */}
          <div className="flex pl-6 pr-4 mt-4 mb-4 space-x-4">
            <div className="relative flex-1">
              <TextInput
                name="search"
                placeholder={`Search${
                  selectedGroups?.length > 0 ? ` ${selectedGroups.length} groups` : ""
                }`}
                className="relative w-full"
                value={query}
                onChange={queryOnChange}
                onKeyDown={handleOnKeyDown}
                prefix={<SearchIcon className="text-zinc-400" aria-hidden="true" />}
                forwardedRef={searchInputRef}
                hotkey={["Alt", "KeyF"]}
                hotkeyHandler={searchInputHotkeyHandler}
                autoComplete="off"
              />

              {query && (
                <button
                  className={"absolute w-8 h-9 right-0 top-0 mt-px"}
                  tabIndex={-1}
                  onClick={onClickClearQuery}
                >
                  <TimesCircleSolidIcon className="cursor-pointer text-zinc-400 hover:text-zinc-500 dark:text-zinc-600" />
                </button>
              )}
            </div>

            <Button
              variant={ButtonVariant.Secondary}
              onClick={onClickAddNewContact}
              hotkey={["Alt", "KeyN"]}
            >
              <Link href={{ pathname: "/contacts/[[...slug]]" }} as="/contacts/new" shallow>
                + New
              </Link>
            </Button>
          </div>

          {/* Groups section */}
          {selectedGroups?.length > 0 && (
            <div className="px-6 mt-1 mb-5">
              {selectedGroups.map((group, index) => {
                const { backgroundColor, foregroundColor } = generateGroupTagColors(
                  group.name,
                  isDarkMode
                );
                return (
                  <Tag
                    key={index}
                    tagItem={{ id: group.id, name: group.name }}
                    onRemoveTag={() => onRemoveSelectedGroupTag(group.id)}
                    enableRemoveButton
                    backgroundColor={backgroundColor}
                    foregroundColor={foregroundColor}
                  />
                );
              })}
            </div>
          )}

          {/* Contact list section */}
          {ContactList}
        </aside>

        {/* Contact details view */}
        <main
          className={classNames(
            "relative flex-col flex-1 overflow-y-auto focus:outline-none",
            isValidUuid(uriRoot) ? "flex" : "hidden xl:flex"
          )}
        >
          <BreadcrumbNavigation className="hidden px-4 pt-6 lg:flex xl:hidden sm:px-6 lg:px-8" />
          {MainDetailsComponent}
        </main>
      </div>
    </div>
  );
};

export default ContactsComponent;
